const optionFilterObj = {
  _idFournisseur: undefined,
  _idGamme: undefined,
  _idIndice: undefined,
  _filterCount: 0
}

Object.defineProperty(optionFilterObj, 'idFournisseur', {
  enumerable: true,
  get () {
    return this._idFournisseur
  },
  set (newVal) {
    //console.log(newVal)
    if (newVal === '') {
      this._idFournisseur = undefined
      if (this._filterCount > 0) this._filterCount--
    } else {
      if (this._idFournisseur === undefined) {
        //console.log(this._filterCount)
        this._filterCount++
      }
      this._idFournisseur = newVal
    }
  }
})

Object.defineProperty(optionFilterObj, 'idGamme', {
  enumerable: true,
  get () {
    return this._idGamme
  },
  set (newVal) {
    //console.log(newVal)
    if (newVal === '') {
      this._idGamme = undefined
      if (this._filterCount > 0) this._filterCount--
    } else {
      if (this._idGamme === undefined) {
        //console.log(this._filterCount)
        this._filterCount++
      }
      this._idGamme = newVal
    }
  }
})

Object.defineProperty(optionFilterObj, 'idIndice', {
  enumerable: true,
  get () {
    return this._idIndice
  },
  set (newVal) {
    //console.log(newVal)
    if (newVal === '') {
      this._idIndice = undefined
      if (this._filterCount > 0) this._filterCount--
    } else {
      if (this._idIndice === undefined) {
        //console.log(this._filterCount)
        this._filterCount++
      }
      this._idIndice = newVal
    }
  }
})

Object.defineProperty(optionFilterObj, 'filterCount', {
  enumerable: true,
  get () {
    return this._filterCount
  },
  set (newVal) {
    this._filterCount = newVal
  }
})

export default optionFilterObj
